import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [code, setCode] = useState<string>("");

  const navigate = useNavigate();

  const styles: React.CSSProperties = {
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4)), url(/bgconfirmation.jpg)",
    backgroundSize: "cover",
    position: "relative",
  };

  const handleSubmitCode = () => {
    navigate(`/${code}`);
  };

  return (
    <>
      <div
        className="w-full flex items-center justify-center h-screen p-6"
        style={styles}
      >
        <div className="w-full max-w-xl bg-white rounded-xl p-6 shadow-lg">
          <>
            <h1 className="text-3xl font-bold">Hai</h1>
            <div className="mt-2">
              <p className="text-lg">
                Silahkan masukkan token konfirmasi di bawah ini
              </p>
              <div className="mt-2">
                <input
                  type="text"
                  placeholder="Masukkan token konfirmasi"
                  className="p-2 text-lg w-full rounded-lg border-gray-400"
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div className="mt-3">
                <button
                  className="w-full bg-blue-700 text-white rounded-lg p-2"
                  onClick={handleSubmitCode}
                >
                  Lanjutkan
                </button>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Home;
