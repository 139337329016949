import { createBrowserRouter } from "react-router-dom";
import Confirmation from "./pages/confirmation";
import Home from "./pages/home";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/:token",
    element: <Confirmation />,
  },
]);
